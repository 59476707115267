import React from 'react';

import {AppState, Auth0Provider} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

import JwtProvider from './JwtContextProvider';

interface Props {
    domain: string;
    clientId: string;
    audience: string;
    children: React.ReactNode;
}

export const Auth0HistoryProvider: React.FC<Props> = ({domain, clientId, audience, children}) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && audience)) {
        // TODO - log error, make this something else
        return <div>Invalid authorization configuration, please contact someone</div>;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: `${window.location.origin}/`,
            }}
            cacheLocation={process.env.NODE_ENV === 'development' ? 'localstorage' : 'memory'}
            useRefreshTokens
            useRefreshTokensFallback
            onRedirectCallback={onRedirectCallback}
        >
            <JwtProvider>{children}</JwtProvider>
        </Auth0Provider>
    );
};
